@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar {
  width: 0; /* Remove default scrollbar width */
  background-color: transparent; /* Make the scrollbar area transparent */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Add this CSS to your existing styles */
.footer {
  display: flex;
  flex-direction: inline;
  align-items: center;
  margin-top: 20px;
 align-items: center;
 text-align: center;
 justify-content: center; 
}

.footer_b{
  background: linear-gradient(to bottom, #f7f7f7, rgb(78, 78, 78));
  padding: 5px;
}

input[type="file"] {
  border:1px solid grey;
  padding: 5px 10px;
  border-radius: 5px;
}


button {
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}



button:hover {
  background-color: #0056b3;
}

progress {
  width: 100%;
  height: 10px;
  margin-top: 10px;
}

