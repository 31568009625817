.login-container{
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
}

.login-background-video {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .box1{
    display: flex;
    padding: 20px;
    border: 1px solid rgb(82, 82, 82) ;
    background-color: rgba(144, 144, 144, 0.568);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 5px rgba(73, 73, 73, 0.3);
  }

  .box2{
    border-radius: 5px;
    padding: 10px;
    border: transparent;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  }

  .box3{
    background-color: rgb(11, 184, 158);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  }