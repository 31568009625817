@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');


.App {
  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  height: 100vh;
  background: #feffff;
  display: flex;
  padding: 0 40px;
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.App::-webkit-scrollbar {
  width: 0; /* Remove default scrollbar width */
  background-color: transparent; /* Make the scrollbar area transparent */
}

.menuContainer {
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 15px;
  position: fixed;
  top: 0;
}

.subMenu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Orbitron', sans-serif;
  /* For vertical text, use writing-mode and text-orientation */
  writing-mode: vertical-lr; /* Vertical, right-to-left */
  text-orientation: mixed; /* Allows vertical and horizontal text within the same line */
  white-space: nowrap;
  font-size: 35px;
  color: #444;
}

.header{
  height: 100vh;
  background-image: url('./img/Landing.JPG');
  background-repeat: no-repeat; /* By default, the image will repeat vertically and horizontally */
  background-position: center center; /* You can adjust the position of the image within the div */
  /* If the image is not covering the entire div, you can use background-size */
  background-size: cover; 
  filter: grayscale(100%);
  overflow: hidden; 
}
.header::after {
  content: '';
  position: absolute;
  bottom: -20px; /* Position the pseudo-element just below the original bottom border */
  left: -24px;
  width: 105vw;
  height: 70px;
  background-color: rgb(255, 255, 255);
  filter: blur(10px); /* Apply the blur effect to the pseudo-element */
  z-index: -1; /* Send the pseudo-element below the box content */
}

.header_landing{
  position: absolute;
  right: 550px;
  bottom: 70px;
  color: #757677;
  font-family: 'Sacramento', cursive;
}
.MuiSvgIcon-root {
  color: #313233;
  font-size: 18px;
}

.iconContainer {
  width: 50px;
  height: 50px;
  min-width: 40px;
  border: 2px solid #757677;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40%;
  margin: 15px 0;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: 0.3s;
}

.iconContainer.black,
.iconContainer:hover {
  background: #2d2e2f;
}

.iconContainer.black .MuiSvgIcon-root,
.iconContainer:hover .MuiSvgIcon-root {
  color: #feffff;
}

.logo {
  width: 70px;
  min-width: 40px;
}

main {
  flex: 1;
  height: 100%;
  padding-top: 35px;
}

@media (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }
  .header {
    background-size: 150% auto; /* Image width will be 100% of the container, and the height will be automatic */
  }
  .pin.small,
  .pin.medium,
  .pin.large {
    grid-row-end: span 22; /* Adjust the row span to make the boxes smaller */
  }
 
}

.searchBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #feffff;
  border: 2px solid #757677;
  padding: 8px 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.searchBox input {
  height: 100%;
  border: none;
  outline: none;
  width: 100%;
  padding: 0px 15px;
  color: #313233;
  font-size: 18px;
  font-weight: bold;
}
.search {
  background: #000001;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.search img {
  width: 25px;
}

.mainContainer {
  flex: 1;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-rows: 10px;
  margin-top: 15px;
  justify-content: center; 
  
}

@media (max-width: 768px) {
  .mainContainer {
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-auto-rows: 10px;
    margin-top: 15px;
    justify-content: center; 
    overflow: hidden;
  }
}


@media (min-width: 375px) and (max-width: 667px) {
  .mainContainer {
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    grid-auto-rows: 4px;
    margin-top: 15px;
    justify-content: center; 
  }
  .header {
    background-size: 270% 80vh; /* Image width will be 100% of the container, and the height will be automatic */
    background-size: cover;
    background-position: -450px center;
    overflow: hidden; 
  }
  .header::after {
    content: '';
    position: absolute;
    bottom: -100px; /* Position the pseudo-element just below the original bottom border */
   left: -16px;
    width: 108vw;
    height: 70px;
    background-color: rgb(255, 255, 255);
    filter: blur(10px); /* Apply the blur effect to the pseudo-element */
    z-index: -1; /* Send the pseudo-element below the box content */
  }
  .header_landing{
    position: absolute;
    top: 10px;
    left: 50px;
    color: #757677;
    font-family: 'Sacramento', cursive;
  }
  .App {
    padding: 0 20px; overflow-y: auto;
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
  }
  .App::-webkit-scrollbar {
    width: 0; /* Remove default scrollbar width */
    background-color: transparent; /* Make the scrollbar area transparent */
  }
  /*------------Modal CSS--------------*/
/* Modal Styles */
.modal-container {
  display: none; /* Hide the modal container by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  overflow: auto; /* Enable scrolling in the modal */
  
}

.modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.751);
  z-index: 1;
  overflow: hidden; /* Enable scrolling in the modal */
}

.Slider_btn{
  width: 20%;
  margin: 0;
}

.modal-content {
  overflow: auto;
  text-align: center;
  max-width: 100vw;
  max-height: auto;
  position: relative; /* Ensure the close button is positioned relative to this container */
}

/* Close button in the modal */
.close {
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
}

.close:hover {
  color: #ccc;
}

/* Hide the scrollbar of the main content when the modal is open */
body.modal-open {
  overflow: hidden;
}

/* Style for the image in the modal */
.modal-image {
  max-width: 100%;
  max-height: auto;/* Adjust this value as needed */
  max-width: 100vw;
  width: 100vw;
  height: auto;
  object-fit: contain; /* Maintain aspect ratio and fit image within container */
  object-position: center;
}
/* CSS for Arrow Buttons */
.Button_fb {
  color: rgb(0, 0, 0);
  font-size: 100px;
  background-color: #fff;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
}

.Button_fb:hover {
  background-color: #333;
}

/*----------------------------*/
}

.pin {
  border-radius: 16px;
  background: #444;
  margin: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.pin .mainPic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pin.small {
  grid-row-end: span 26;
}
.pin.medium {
  grid-row-end: span 33;
}
.pin.large {
  grid-row-end: span 45;
}

.pin .content {
  width: 100%;
  height: 50px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  bottom: -55px;
  left: 0;
  border-radius: 16px;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*------------Modal CSS--------------*/
/* Modal Styles */
.modal-container {
  display: none; /* Hide the modal container by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  overflow: auto; /* Enable scrolling in the modal */
  
}

.modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.751);
  z-index: 1;
  overflow: hidden; /* Enable scrolling in the modal */
}

.Slider_btn{
  width: 20%;
  margin: 0;
}

.modal-content {
  overflow: auto;
  text-align: center;
  max-width: 100vw;
  max-height: 100vh;
  position: relative; /* Ensure the close button is positioned relative to this container */
}

/* Close button in the modal */
.close {
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
}

.close:hover {
  color: #ccc;
}

/* Hide the scrollbar of the main content when the modal is open */
body.modal-open {
  overflow: hidden;
}

/* Style for the image in the modal */
.modal-image {
  width: auto;
  max-height: 95vh; /* Adjust this value as needed */
  object-fit: cover;
  object-position: center;
}
/* CSS for Arrow Buttons */
.Button_fb {
  color: rgb(0, 0, 0);
  font-size: 100px;
  background-color: #fff;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
}

.Button_fb:hover {
  background-color: #333;
}

/*----------------------------*/

.pin:hover .content {
  bottom: 0;
  transition: bottom 0.3s;
  padding: 0px 15px;
}

.pin:hover .content h3 {
  color: #feffff;
  font-size: 18px;
  font-weight: 500;
}

.pin .search {
  background: #fb3874;
}


@media screen and (max-width: 480px) {
  .App {
    padding: 0 20px; overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
  }
  .App::-webkit-scrollbar {
    width: 0; /* Remove default scrollbar width */
    background-color: transparent; /* Make the scrollbar area transparent */
  }

  .menuContainer {
    padding: 30px 0px;
    left: 15px;
    width: unset;
  }

  main {
    margin-left: 75px;
  }
}
